
import { Options } from "vue-property-decorator";
import { DEFAULT_DATE_FORMAT } from "@/config/Formats";
import UserService from "@/services/UserService";
import { downloadFile, getFile } from "@/utils/File";
import { mixins } from "vue-class-component";
import StudentDocumentsStoreMixin from "@/mixins/StudentDocumentsStoreMixin";
import ModalMixin from "@/mixins/ModalMixin";
import { IonContent } from "@ionic/vue";
import PdfView from "@/components/PdfView.vue";
import { Capacitor } from "@capacitor/core";

@Options({
  components: {
    IonContent,
    PdfView: PdfView,
  },
})
export default class DocsList extends mixins(StudentDocumentsStoreMixin, ModalMixin) {
  public name = "DocsList";
  private dateFormat = DEFAULT_DATE_FORMAT;
  private documentSrc = "";

  public scale = "page-width";

  public url = "";

  private downloadIsLoading = false;

  public mounted(): void {
    this.mountedLoading = true;
    this.loadStudentDocuments();
  }

  public get isDesktop(): boolean {
    return !Capacitor.isNativePlatform();
  }

  private getDocNameByType(doc: any): string {
    return doc.name;
  }

  private markAsSeen(doc: any): void {
    if (doc.seenByStudent) {
      return;
    }
    doc.seenByStudent = true;
  }

  private isInvoice(doc: any): boolean {
    return doc.documentType === "INVOICE" || doc.documentType === "FINAL_INVOICE";
  }

  private showPreview(doc: any): void {
    this.documentSrc = "";
    this.url = "";
    const baseUrl = process.env.VUE_APP_BACKEND_BASE_URL;
    const token = UserService.getToken().replaceAll('"', "");
    const pdfUrl = baseUrl + "/student-documents/student/current/document/" + doc.id + "?token=" + token;
    if (this.isDesktop) {
      getFile(
        {
          method: "get",
          url: pdfUrl,
        },
        false
      ).then((blob: any) => {
        this.documentSrc = blob;
      });
    } else {
      this.url = pdfUrl;
    }
    this.showModal(null, "documentPopover");
    this.markAsSeen(doc);
  }

  private async download(doc: any) {
    this.downloadIsLoading = true;
    await downloadFile(
      {
        url: "/student-documents/student/current/document/" + doc.id,
      },
      "document.pdf"
    );
    this.markAsSeen(doc);
    this.downloadIsLoading = false;
  }

  private isRelatedWithCostBearer(doc: any): boolean {
    return Boolean(doc?.costBearerDocument);
  }

  private onHideModal() {
    this.hideModal(null, "documentPopover");
  }

  private get isLoading() {
    return this.loadingDocs || this.downloadIsLoading;
  }
}
