
import { Options, Vue } from "vue-property-decorator";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import DocsHero from "@/components/Pages/Docs/DocsHero.vue";
import DocsList from "@/components/Pages/Docs/DocsList.vue";
import ForwardHeader from "@/components/Headers/ForwardHeader.vue";

@Options({
  components: {
    ForwardHeader,
    DocsList,
    DocsHero,
    DefaultLayout,
  },
})
export default class Docs extends Vue {
  public name = "Docs";
}
